import React from 'react'
import './Page.css'
import InputLine from './InputLine'
import Room from './Room'
import Footer from './Footer'
import Header from './Header'

const config = require("./config.json")

function number2str(num) {
  if ( num === undefined ) return ""
  if ( isNaN(num) ) return ""
  return num
}

function numOrZero(num) {
  if ( num === undefined ) return 0
  if ( isNaN(num) ) return 0
  if ( num === "" ) return 0
  return parseFloat(num)
}

function round2(value) {
  return Math.round(value * 100) / 100;
}

function meters2feet(meters) {
  return round2(meters * 10.764);
}

function meters2feetinches(meters) {
  var inchesTotal = meters * 39.3700787;
  var feet = Math.floor(inchesTotal / 12);
  var inches = Math.round(inchesTotal % 12);
  if ( inches == 12 ) {
    feet++;
    inches = 0;
  }
  if ( inches != 0 ) {
      return feet + "' " + Math.round(inches) + '"';
  }
  else {
    return feet + "' ";
  }
}

export default class MeasuermentsPage extends React.Component {
  onChange(prop, value) {
    if ( this.props.onPropertyChange ) {
      this.props.onPropertyChange(prop, value)
    }
  }

  onArrayPropertyChange(arrayProp, index, itemProp, value) {
    if ( this.props.onArrayPropertyChange ) {
      this.props.onArrayPropertyChange(arrayProp, index, itemProp, value)
    }
  }

  onDeleteArrayItem(arrayProp, index) {
    if ( this.props.onDeleteArrayItem ) {
      this.props.onDeleteArrayItem(arrayProp, index)
    }
  }

  addRoom() {
    this.props.onAddArrayItem("Rooms", {
      Type: "",
      Level: "",
      Width: "",
      Height: ""
    })
  }

  render() {
    return (
      <div className="page">
        <Header Address={this.props.Address} Date={this.props.PackageDate} />
        <Footer PageNum={this.props.PageNum} TotalPages={this.props.TotalPages} />
        <div className="tbl property_info">
          <table>
            <tbody>
              <tr>
                <td>
                  <span className="label">Type</span>
                  <InputLine allowedValues={config.PropertyTypes} value={this.props.PropertyType} onChange={this.onChange.bind(this, "PropertyType")} />
                </td>
                <td>
                <span className="label">Parking</span>
                  <InputLine allowedValues={config.ParkingTypes} value={this.props.ParkingType}  onChange={this.onChange.bind(this, "ParkingType")} />
                </td>
              </tr>
              <tr>
                <td>
                  <span className="label">Style</span>
                  <InputLine allowedValues={config.StyleTypes} value={this.props.PropertyStyle} onChange={this.onChange.bind(this, "PropertyStyle")} />
                </td>
                <td>
                  <span className="label">Basement</span>
                  <InputLine allowedValues={config.BasementTypes} value={this.props.BasementType} onChange={this.onChange.bind(this, "BasementType")} />
                </td>
              </tr>
              <tr>
                <td>
                  <span className="label">Wall Thickness</span>
                  <InputLine allowedValues={config.WallThickness} value={this.props.WallThickness} onChange={this.onChange.bind(this, "WallThickness")} />
                </td>
                <td>
                  <span className="label">Bathrooms</span>
                  <InputLine value={this.props.Bathrooms} onChange={this.onChange.bind(this, "Bathrooms")} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbl rms_area">
          <table>
            <tbody>
              <tr>
                <td><span className="label">RMS Floor Area</span></td>
                <td><span className="label">sq.ft.</span></td>
                <td><span className="label">sq.m.</span></td>
              </tr>
              <tr>
                <td>Main Floor Area</td>
                <td>
                  {meters2feet(numOrZero(this.props.MainFloorSQM))}
                </td>
                <td>
                  <InputLine 
                    value={this.props.MainFloorSQM}
                    onChange={this.onChange.bind(this, "MainFloorSQM")} />
                </td>
              </tr>
              <tr>
                <td>Upper Floor(s) Area</td>
                <td>
                  {meters2feet(numOrZero(this.props.UpperFloorSQM))}
                </td>
                <td>
                  <InputLine 
                    value={this.props.UpperFloorSQM}
                    onChange={this.onChange.bind(this, "UpperFloorSQM")} />
                </td>
              </tr>
              <tr>
                <td>Total Above Grade Area</td>
                <td>
                  {number2str(meters2feet(numOrZero(this.props.MainFloorSQM) + numOrZero(this.props.UpperFloorSQM)))}
                </td>
                <td>
                  {number2str(numOrZero(this.props.MainFloorSQM) + numOrZero(this.props.UpperFloorSQM))}
                </td>
              </tr>
              <tr>
                <td><span className="label">Other Floor Area</span></td>
                <td><span className="label">&nbsp;</span></td>
                <td><span className="label">&nbsp;</span></td>
              </tr>
              <tr>
                <td>Total Lower Development</td>
                <td>
                  {meters2feet(numOrZero(this.props.LowerFloorSQM))}
                </td>
                <td>
                  <InputLine 
                    value={this.props.LowerFloorSQM}
                    onChange={this.onChange.bind(this, "LowerFloorSQM")} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbl rooms">
          <table>
            <tbody>
              <tr>
                <td>
                  <span className="label">Room</span>
                </td>
                <td>
                  <span className="label">Level</span>
                </td>
                <td>
                  <span className="label">ft.in.</span>
                </td>
                <td>
                  <span className="label">meters</span>
                </td>
                <td>&nbsp;</td>
              </tr>
              {this.props.Rooms.map((room, index) => (
                <Room 
                  onArrayPropertyChange={this.onArrayPropertyChange.bind(this, "Rooms", index)}
                  onDelete={this.onDeleteArrayItem.bind(this, "Rooms", index)}
                  Type={room.Type}
                  Level={room.Level}
                  Width={room.Width}
                  Depth={room.Depth} />
              ))}
              <tr>
                <td><a href="javascript:void(0);" className="noprint" onClick={this.addRoom.bind(this)}>+ Add Room</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="tbl">
          <span className="label">Additional Comments</span><br />
          <InputLine value={this.props.Comments} onChange={this.onChange.bind(this, "Comments")} />
        </div>
      </div>
    )
  }
}