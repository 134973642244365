import React from 'react'
import './App.css'
import CoverPage from './CoverPage'
import MeasurementsPage from './MeasurementsPage'
import DisclaimerPage from './DisclaimerPage'
import DiagramPage from './DiagramPage'
import Toolbar from './Toolbar'
import * as uuid from 'uuid'
import Cloud from './Cloud'
import ImageUtil from './ImageUtil'
import { route } from './Util'

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]

function todaysDate() {
  var d = new Date()
  return `${monthNames[d.getMonth()]} ${d.getDate()} ${d.getFullYear()}`
}

export default class Editor extends React.Component {
  constructor() {
    super()
    this.cloud = new Cloud()
    this.state = {}
  }

  componentDidMount() {
    this.componentDidUpdate({}, this.state)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.id !== this.props.id && this.props.id !== "new" ) {
      this.setState({__Loading: true})
      this.cloud.loadMeasurements(this.props.id)
      .then(item => {
        item.__Dirty = false
        item.__Saving = false
        this.setState(item)
      })
      .catch(err => {
        window.alert("Error loading measurements")
        console.log(err)
      })
    }
    else if ( prevProps.id !== "new" && this.props.id === "new" ) {
      var tmp = {
        Partition: "main",
        Id: uuid.v4().toString(),
        CreateDate: new Date().getTime(),
        Address: "",
        PreparedFor: "",
        PackageDate: todaysDate(),
        PropertyType: "",
        PropertyStyle: "",
        ParkingType: "",
        BasementType: "",
        WallThickness: "",
        Bathrooms: "",
        MainFloorSQM: "",
        UpperFloorSQM: "",
        LowerFloorSQM: "",
        Comments: "",
        Rooms: [],
        Diagrams: [{Url: ""}],
        Image1: "",
        Image2: "",
        Image3: ""
      }
      this.cloud.saveMeasurements(tmp)
        .then(() => {
          route({id: tmp.Id})
        })
        .catch(err => {
          console.log(err)
          window.alert("Error creating new package")
        })
    }
  }

  onPropertyChange(prop, value) {
    var st = {}
    st[prop] = value
    this.setState(st)
    this.saveChanges()
  }

  numPages() {
    return 3 + this.state.Diagrams.length
  }

  insertDiagramPage() {
    var st = this.state
    this.state.Diagrams.push({ Url: "" })
    this.setState(st)
    this.saveChanges()
  }

  onArrayPropertyChange(arrayProp, index, itemProp, value) {
    var arr = this.state[arrayProp]
    var item = arr[index]
    item[itemProp] = value
    var st = {}
    st[arrayProp] = arr
    this.setState(st)
    this.saveChanges()
  }

  onDeleteArrayItem(arrayProp, index) {
    var arr = this.state[arrayProp]
    arr.splice(index, 1)
    var st = {}
    st[arrayProp] = arr
    this.setState(st)
    this.saveChanges()
  }

  onAddArrayItem(arrayProp, item) {
    var arr = this.state[arrayProp]
    arr.push(item)
    var st = {}
    st[arrayProp] = arr
    this.setState(st)
    this.saveChanges()
  }

  uploadPhoto(prop, file) {
    var onProgress = function (p, msg) {
      var st = {}
      st[p] = ""
      st["__" + p + "UploadStatus"] = msg
      this.setState(st)
    }.bind(this, prop)

    var util = new ImageUtil(file, onProgress, 1000)
    util.resize()
      .then(resizedBlob => {
        var st = {}
        st[prop] = ""
        st["__" + prop + "UploadStatus"] = "Uploading..."
        this.setState(st)
        
        this.cloud.uploadPhoto(resizedBlob)
          .then(url => {
            var st = {}
            st[prop] = url
            st["__" + prop + "UploadStatus"] = ""
            this.setState(st)
            this.saveChanges()
          })
          .catch(err => {
            var st = {}
            st[prop] = ""
            st["__" + prop + "UploadStatus"] = ""
            this.setState(st)
            console.log(err)
            window.alert("Error uploading photo")
          })
      })
      .catch(err => {
        console.log(err)
        var st = {}
        st[prop] = "s"
        st["__" + prop + "UploadStatus"] = ""
        this.setState(st)
        window.alert("Error resizing photo")
      })
  }

  onBack() {
    setTimeout(() => {
      if ( this.state.__Dirty ) {
        this.saveChanges(true)
      }
      else {
        this.props.onBack()
      }
    }, 0)
  }

  saveChanges(backOnDone) {
    setTimeout(() => {
      if ( this.state.__Saving ) {
        this.setState({__Dirty: true})
        return
      }
      else {
        this.setState({__Dirty: false, __Saving: true})
        this.cloud.saveMeasurements(this.state)
          .then(result => {
            this.onSaveSuccess(result)
            if ( backOnDone === true ) {
              setTimeout(() => {
                this.props.onBack()
              }, 50)
            }
          })
          .catch(this.onSaveFailure.bind(this))
      }
    }, 1)
  }

  onSaveSuccess(result) {
    this.setState({__Saving: false})
    if ( this.state.__Dirty ) this.saveChanges()
  }

  onSaveFailure(err) {
    console.log(err)
    this.setState({__Saving: false})
    window.alert("There was an error saving changes. Please try refreshing the page.")
  }

  render() {
    if ( !this.state.Id ) {
      return (<div className="editor">Loading...</div>)
    }
    return (
      <div className="editor">
        <Toolbar
          mode="editor"
          onBack={this.onBack.bind(this)}
          dirty={this.state.__Dirty}
          saving={this.state.__Saving} />
        <CoverPage
          onPropertyChange={this.onPropertyChange.bind(this)}
          Address={this.state.Address}
          PackageDate={this.state.PackageDate}
          PreparedFor={this.state.PreparedFor}
          Image1={this.state.Image1}
          Image2={this.state.Image2}
          Image3={this.state.Image3}
          Image1UploadStatus={this.state.__Image1UploadStatus}
          Image2UploadStatus={this.state.__Image2UploadStatus}
          Image3UploadStatus={this.state.__Image3UploadStatus}
          uploadPhoto={this.uploadPhoto.bind(this)} />
        <MeasurementsPage
          onPropertyChange={this.onPropertyChange.bind(this)}
          onArrayPropertyChange={this.onArrayPropertyChange.bind(this)}
          onDeleteArrayItem={this.onDeleteArrayItem.bind(this)}
          onAddArrayItem={this.onAddArrayItem.bind(this)}
          Address={this.state.Address}
          PackageDate={this.state.PackageDate}
          PropertyType={this.state.PropertyType}
          PropertyStyle={this.state.PropertyStyle}
          ParkingType={this.state.ParkingType}
          BasementType={this.state.BasementType}
          MainFloorSQM={this.state.MainFloorSQM}
          UpperFloorSQM={this.state.UpperFloorSQM}
          LowerFloorSQM={this.state.LowerFloorSQM}
          WallThickness={this.state.WallThickness}
          Bathrooms={this.state.Bathrooms}
          Rooms={this.state.Rooms}
          Comments={this.state.Comments}
          PageNum="2"
          TotalPages={this.numPages()} />
        {this.state.Diagrams.map((diagram, index) => (
          <div key={diagram + "_" + index}>
            <DiagramPage
              Address={this.state.Address}
              PackageDate={this.state.PackageDate}
              Url={diagram.Url}
              PageNum={3 + index}
              TotalPages={this.numPages()} />
            <div className="belowpage"><a href="javascript:void(0);" onClick={this.onDeleteArrayItem.bind(this, "Diagrams", index)}>Delete Page</a></div>
          </div>
        ))}
        <div className="betweenpages"><a href="javascript:void(0);" onClick={this.insertDiagramPage.bind(this)}>+ Insert Diagram Page</a></div>
        <DisclaimerPage
          Address={this.state.Address}
          PackageDate={this.state.PackageDate}
          PageNum={this.numPages()}
          TotalPages={this.numPages()} />
      </div>
    )
  }
}