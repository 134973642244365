import React from 'react'
import './InputLine.css'

export default class InputLine extends React.Component {
  constructor() {
    super()
    this.state = {
      focused: false
    }
  }

  showDropdown() {
    this.setState({focused: true})
  }

  hideDropdown() {
    this.setState({focused: false})
  }

  chooseOption(value) {
    this.hideDropdown()
    this.handleChange(value)
  }

  handleChange(value) {
    if ( this.props.onChange ) {
      if ( value != this.props.noValueText ) {
        this.props.onChange(value)
      }
    }
  }

  onChange(e) {
    this.handleChange(e.target.innerHTML)
  }

  render() {
    var value = this.props.value
    var isDropdown = !!this.props.allowedValues
    var classes = ["input_line"]
    var focused = this.state.focused
    var inline = this.props.inline

    if ( !value ) {
      classes.push("novalue")
      value = this.props.noValueText || (!isDropdown ? "" : "-- select --")
    }
    if ( isDropdown ) {
      classes.push("dropdown")
    }
    if ( inline ) {
      classes.push("inline")
    }
    
    if ( !isDropdown ) {
      return (<div contentEditable="true" onBlur={this.onChange.bind(this)} className={classes.join(" ")} tabIndex="0" dangerouslySetInnerHTML={{__html: value}}></div>)
    }
    else {
      if ( !focused ) {
        return (<div className={classes.join(" ")} tabIndex="0" onClick={this.showDropdown.bind(this)}>{value}</div>)
      }
      else {
        this.props.allowedValues.sort()
        return (
          <div className={classes.join(" ")} tabIndex="0" onBlur={this.hideDropdown.bind(this)}>
            <div className="dropdown_wrap">
              {this.props.allowedValues.map(val => (
                <div key={val} onClick={this.chooseOption.bind(this, val)}>{val}</div>
              ))}
            </div>
            {value}
          </div>
        )
      }
    }
  }
}