import React from 'react'
import './Page.css'
import Footer from './Footer'
import Header from './Header'

export default class DisclaimerPage extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className="page">
        <Header Address={this.props.Address} Date={this.props.PackageDate} />
        <Footer PageNum={this.props.PageNum} TotalPages={this.props.TotalPages} />
        <div className="notice">
          <div className="title">Real Estate Council of Alberta (RECA) - RECA Measuring System (RMS)</div>
          Effective September 1st, 2016 all residential real estate including 
          condominiums must be measured in accordance to RMS.
          <br /><br />
          Any listings placed on the MLS after September 1st, 2016 must reflect 
          the RMS size only. RMS does not have any rules for measuring what is 
          considered below grade developed floor area so for the purpose of these
          documents all developed floor areas below grade will be measured using 
          the paint to paint method and must be fully developed with permanent 
          heating. Any undeveloped areas will be shaded in grey and no deductions 
          for staircases will be made.
          <br /><br />
          To view a complete copy of the RMS rules and guidelines go to the 
          following web address: http://condosize.ca/RMS.pdf

          Equipment Used in Measuring: Computerized drafting software, Digital laser measurer, 7.5 &amp; 30 meter measuring tapes.
        </div>
        <div className="notice">
          <div className="title">Notice to Realtors, Buyer, and Seller</div>
          You are hereby advised that you should look over these documents 
          carefully to see if there is any area that may have been over looked 
          or left out by mistake or that may not clearly be shown on these plans. 
          If there is an area that is considered above grade, fully developed 
          and heated that should be included in the Living Area Size and is not, 
          please contact the writer with specific details as to this information 
          and the necessary adjustments will be made to these documents and a 
          revised set of documents will be supplied to you.

          Under no circumstances is the writer required to give testimony in any 
          legal proceedings or inquiries unless a separate contract has been entered 
          into in writing between the writer and the party wishing to use the services 
          of the writer and a monetary amount has been agreed to and paid in advance.
        </div>
        <div className="notice">
        While great care was taken to ensure that this information is accurate and correct it is not warranted to be so. Calculations and Sizes based on RMS Rules.
        </div>
      </div>
    )
  }
}