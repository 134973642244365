import * as AWS from 'aws-sdk'
import * as uuid from 'uuid'

const env = require("./env")

export default class Cloud {
  constructor() {
    this.dynamodb = new AWS.DynamoDB.DocumentClient({region: env.Region})
    this.s3 = new AWS.S3({region: env.Region})
  }

  queryMeasurements(numDays) {
    var days = (new Date().getTime()) - (1000 * 60 * 60 * 24 * numDays)

    return new Promise((resolve, reject) => {
      var params = {
        TableName: env.TableName,
        IndexName: "Search",
        ScanIndexForward: true,
        KeyConditions: {
          Partition: {
            ComparisonOperator: "EQ",
            AttributeValueList: ["main"]
          },
          CreateDate: {
            ComparisonOperator: "GT",
            AttributeValueList: [days]
          }
        }
      }
      this.dynamodb.query(params, (err, data) => {
        if ( err ) {
          reject(err)
        }
        else {
          data.Items.sort((a, b) => (a.CreateDate > b.CreateDate) ? 1 : (a.CreateDate === b.CreateDate) ? ((a.CreateDate > b.CreateDate) ? 1 : -1) : -1 )
          data.Items.reverse()
          resolve(data.Items)
        }
      })
    })
  }

  uploadPhoto(resizedBlob) {
    return new Promise((resolve, reject) => {
      var filename = `${uuid.v4()}.jpg`
      var key = `photos/${filename}`
      var url = `${env.DataCDNUrl}/${key}`
      var params = {
        Bucket: env.DataBucket,
        Key: key,
        Body: resizedBlob
      }
      this.s3.putObject(params, (err, data) => {
        if ( err ) {
          reject(err)
        }
        else {
          resolve(url)
        }
      })
    })
  }

  loadMeasurements(id) {
    return new Promise((resolve, reject) => {
      this.dynamodb.get({TableName: env.TableName, ConsistentRead: true, Key: {"Id": id}}, (err, data) => {
        if ( err ) {
          reject(err)
        }
        else {
          resolve(data.Item)
        }
      })
    })
  }

  deleteMeasurements(id) {
    return new Promise((resolve, reject) => {
      this.dynamodb.delete({TableName: env.TableName, Key: {"Id": id}}, (err, data) => {
        if ( err ) {
          reject(err)
        }
        else {
          resolve()
        }
      })
    })
  }

  saveMeasurements(obj) {
    var copy = JSON.parse(JSON.stringify(obj))
    Object.keys(copy).forEach(key => {
      if ( key.startsWith("__") ) {
        delete copy[key]
      }
    })

    return new Promise((resolve, reject) => {
      this.dynamodb.put({TableName: env.TableName, Item: copy}, (err, res) => {
        if ( err ) {
          reject(err)
        }
        else {
          resolve(res)
        }
      })
    })
  }
}