export default class ImageUtil {
  constructor(file, onProgress, maxSize) {
    this.file = file
    this.maxSize = maxSize
    this.onProgress = onProgress
  }

  load() {
    try {
      this.onProgress("Loading File...")
      this.reader = new FileReader();
      this.reader.onload = this.dataOnLoad.bind(this)
      this.reader.readAsDataURL(this.file)
    }
    catch ( err ) {
      this.reject(err)
    }
  }

  dataOnLoad(e) {
    try {
      this.onProgress("Loading Image...")
      this.image = new Image()
      this.image.onload = this.imageOnLoad.bind(this)
      this.image.src = e.target.result
    }
    catch ( err ) {
      this.reject(err)
    }
  }

  imageOnLoad(e) {
    try {
      this.onProgress("Resizing...")
      var canvas = document.createElement('canvas')
      var width = this.image.width
      var height = this.image.height
      
      if (width > height) {
        if (width > this.maxSize) {
          height *= this.maxSize / width;
          width = this.maxSize;
        }
      } else {
        if (height > this.maxSize) {
          width *= this.maxSize / height;
          height = this.maxSize;
        }
      }
      
      canvas.width = width
      canvas.height = height
      canvas.getContext('2d').drawImage(this.image, 0, 0, width, height);
      var dataUrl = canvas.toDataURL('image/jpeg')
      var resizedImage = this.dataURLToBlob(dataUrl)
      this.resolve(resizedImage)
    }
    catch ( err ) {
      this.reject(err)
    }
  }

  dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = parts[1];
      return new Blob([raw], {type: contentType});
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  resize() {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.load()
    })
  }
}