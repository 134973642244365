import React from 'react'
import './Page.css'
import InputLine from './InputLine'
import CoverPhoto from './CoverPhoto'

export default class CoverPage extends React.Component {
  constructor() {
    super()
  }

  onChange(prop, value) {
    if ( this.props.onPropertyChange ) {
      this.props.onPropertyChange(prop, value)
    }
  }

  render() {
    return (
      <div className="page cover">
        <div className="photos">
          <CoverPhoto 
            ImageUrl={this.props.Image1} 
            UploadStatus={this.props.Image1UploadStatus}
            uploadPhoto={this.props.uploadPhoto.bind(this, "Image1")} />
          <CoverPhoto 
            ImageUrl={this.props.Image2}
            UploadStatus={this.props.Image2UploadStatus}
            uploadPhoto={this.props.uploadPhoto.bind(this, "Image2")} />
          <CoverPhoto 
            ImageUrl={this.props.Image3}
            UploadStatus={this.props.Image3UploadStatus}
            uploadPhoto={this.props.uploadPhoto.bind(this, "Image3")} />
        </div>
        <div className="address">
          <InputLine 
            value={this.props.Address} 
            onChange={this.onChange.bind(this, "Address")} />
          <span className="address_sub">
            <InputLine 
              value={this.props.PreparedFor} 
              onChange={this.onChange.bind(this, "PreparedFor")} />
            <span className="date">
              <InputLine 
                value={this.props.PackageDate} 
                onChange={this.onChange.bind(this, "PackageDate")} />
            </span>
          </span>
        </div>
        <div className="business_info">
          <span className="sub"><strong>Prepared By:</strong></span><br />
          Alberta Real Estate Consulting (AREC)<br />
          <span className="sub">www.AlbertaRealEstateConsulting.com<br/>403-616-6801</span>
        </div>
        <div className="extra_info">
          <InputLine
            value={this.props.ExtraInfo}
            onChange={this.onChange.bind(this, "ExtraInfo")} />
        </div>
      </div>
    )
  }
}