import React from 'react'
import './List.css'
import Toolbar from './Toolbar'
import ListRow from './ListRow'
import Cloud from './Cloud'

export default class List extends React.Component {
  constructor() {
    super()
    this.cloud = new Cloud()
    this.state = {
      data: null,
      filter: null,
      days: 14
    }
  }

  componentWillMount() {
    if ( this.state.data === null ) {
      this.refresh()
    }
  }

  refresh() {
    this.setState({data: null})
    console.log("load " + this.state.days)
    this.cloud.queryMeasurements(this.state.days)
      .then(data => {
        this.setState({data: data})
      })
      .catch(err => {
        window.alert("Error loading packages")
      })
  }

  changeDays(e) {
    this.setState({days: parseInt(e.target.value, 10)})
    setTimeout(() => {
      this.refresh()
    }, 0)
  }

  select(id) {
    this.props.onSelect(id)
  }

  delete(id) {
    this.cloud.deleteMeasurements(id)
      .then(() => {
        this.refresh()
      })
      .catch(err => {
        console.log(err)
        window.alert("Error deleting measurements")
      })
  }

  updateFilter(e) {
    this.setState({filter: e.target.value})
  }

  getFilteredData() {
    if ( !this.state.data ) return []
    if ( !this.state.filter ) return this.state.data

    var terms = this.state.filter.split(" ")
    return this.state.data.filter(item => {
      var search = (item.Address + " " + item.PreparedFor + " " + item.PackageDate).toLowerCase()
      var matchesAll = true
      for ( var i=0; i<terms.length; i++ ) {
        var term = terms[i]
        if ( search.indexOf(term) == -1 ) {
          matchesAll = false
        }
      }
      return matchesAll
    })
  }

  render() {
    var tbody = null
    var len = ""
    
    if ( this.state.data == null ) {
      tbody = (
        <tr>
          <td colSpan="4">Loading...</td>
        </tr>
      )
    }
    else {
      len = this.state.data.length
      var res = this.getFilteredData()
      tbody = res.map((item, index) => (
        <ListRow 
          item={item} 
          key={index} 
          onSelect={this.select.bind(this, item.Id)}
          onDelete={this.delete.bind(this, item.Id)} />
      ))
    }
    return (
      <div className="list">
        <Toolbar mode="default" />
        <strong>Recent Packages ({len} in last {this.state.days} days)</strong><br />
        Show last <select value={this.state.days} onChange={this.changeDays.bind(this)}>
          <option>7</option>
          <option>14</option>
          <option>30</option>
          <option>60</option>
          <option>90</option>
          <option>365</option>
          <option>530</option>
          <option>1825</option>
        </select> days
        <table width="100%">
          <thead>
            <tr>
              <th>Package</th>
              <th width="120">Date</th>
              <th>Actions</th>
            </tr>
            <tr>
              <th><input type="text" placeholder="Search..." value={this.state.filter} onChange={this.updateFilter.bind(this)} /></th>
              <th width="120">&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {tbody}
          </tbody>
        </table>
      </div>
    )
  }
}