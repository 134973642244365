import React from 'react'
import './Page.css'
import Footer from './Footer'
import Header from './Header'

export default class DiagramPage extends React.Component {
  constructor() {
    super()
  }

  render() {
    return (
      <div className="page">
        <Header Address={this.props.Address} Date={this.props.PackageDate} />
        <Footer PageNum={this.props.PageNum} TotalPages={this.props.TotalPages} />
        
      </div>
    )
  }
}