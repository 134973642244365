import React from 'react'
import './List.css'

export default class List extends React.Component {
  confirmDelete() {
    var msg = `Are you sure you want to delete this package? This cannot be undone!`
    if ( window.confirm(msg) ) {
      this.props.onDelete()
    }
  }

  render() {
    return (
      <tr>
        <td dangerouslySetInnerHTML={{__html: "<strong>" + this.props.item.Address + "</strong><br />" + this.props.item.PreparedFor}}></td>
        <td dangerouslySetInnerHTML={{__html: this.props.item.PackageDate}}></td>
        <td>
          <a href="javascript:void(0);" onClick={this.props.onSelect}>Edit</a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="javascript:void(0);" onClick={this.confirmDelete.bind(this)}>Delete</a>
        </td>
      </tr>
    )
  }
}