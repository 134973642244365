import React from 'react'
import './Toolbar.css'
import { route } from './Util'

export default class Toolbar extends React.Component {
  constructor() {
    super()
    this.state = {
      preview: false
    }
  }

  toPDF() {
    window.print()
  }

  nothing() {
    
  }

  togglePreview(e) {
    if ( document.body.className !== "inline_preview" ) {
      document.body.className = "inline_preview"
      this.setState({preview: true})
    }
    else {
      document.body.className = ""
      this.setState({preview: false})
    }
    e.preventDefault()
    return false
  }

  render() {
    if ( this.props.mode == "editor" ) {
      return this.renderEditor()
    }
    else {
      return this.renderDefault()
    }
  }

  newPackage() {
    route({id: "new", v: "edit"}, true)
  }

  renderDefault() {
    return (
      <div className="toolbar">
        <a className="btn" href="javascript:void(0);" onClick={this.newPackage.bind(this)}>New Package</a>
      </div>
    )
  }

  renderEditor() {
    var changeInfo = ""
    var cls = "changes_info"

    if ( this.props.dirty ) {
      changeInfo = "Unsaved Changes"
      cls = "changes_info dirty"
    }
    else {
      changeInfo = "Saved"
      cls = "changes_info saved"
    }
    if ( this.props.saving ) {
      changeInfo = "Saving..."
      cls = "changes_info saving"
    }
    return (
      <div className="toolbar">
        <span className="right">
          <label onClick={this.togglePreview.bind(this)}><input type="checkbox" checked={this.state.preview} onChange={this.nothing.bind(this)} /> Print Preview</label>
          <a className="btn" href="javascript:void(0);" onClick={this.toPDF.bind(this)}>Save As PDF</a>
        </span>
        <span className={cls}>{changeInfo}</span>
        <span className="left btn"><a href="javascript:void(0)" onClick={this.props.onBack}>&lt;&lt; Back</a></span>
      </div>
    )
  }
}