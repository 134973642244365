import React from 'react'
import './Page.css'

export default class CoverPhoto extends React.Component {
  handleFile(e) {
    if ( e.target.files.length == 1 ) {
      this.props.uploadPhoto(e.target.files[0])
    }
  }

  render() {
    var cls = "photo"
    if ( this.props.addClass ) cls += " " + this.props.addClass
    var img = this.props.ImageUrl ? (<img src={this.props.ImageUrl} />) : null
    if ( !img ) {
      cls += " none noprint"
    }

    var tools = null
    if ( this.props.UploadStatus ) {
      tools = (
        <div className="tools">
          <div className="upload_status">{this.props.UploadStatus}</div>
        </div>
      )
    }
    else {
      tools = (
        <div className="tools">
          <a href="javascript:void(0);">+ Upload Image</a>
          <input type="file" onChange={this.handleFile.bind(this)} />
        </div>
      )
    }
    return (
      <div className={cls}>
        {img}
        {tools}
      </div>
    )
  }
}