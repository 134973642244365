import React from 'react'
import './Page.css'
import InputLine from './InputLine'
const config = require("./config.json")

function numOrZero(num) {
  if ( num === undefined ) return 0
  if ( isNaN(num) ) return 0
  if ( num === "" ) return 0
  return parseFloat(num)
}

function meters2feetinches(meters) {
  var inchesTotal = meters * 39.3700787;
  var feet = Math.floor(inchesTotal / 12);
  var inches = Math.round(inchesTotal % 12);
  if ( inches == 12 ) {
    feet++;
    inches = 0;
  }
  if ( inches != 0 ) {
      return feet + "' " + Math.round(inches) + '"';
  }
  else {
    return feet + "' ";
  }
}

export default class Room extends React.Component {
  onChange(prop, value) {
    if ( this.props.onArrayPropertyChange ) {
      this.props.onArrayPropertyChange(prop, value)
    }
  }

  render() {
    return (
      <tr>
        <td>
          <InputLine allowedValues={config.RoomTypes} value={this.props.Type} onChange={this.onChange.bind(this, "Type")} />
        </td>
        <td>
        <InputLine allowedValues={config.RoomLevels} value={this.props.Level} onChange={this.onChange.bind(this, "Level")} />
        </td>
        <td>
          {meters2feetinches(numOrZero(this.props.Width))}
          <span>&nbsp;x&nbsp;</span>
          {meters2feetinches(numOrZero(this.props.Depth))}
        </td>
        <td>
          <InputLine inline="true" value={this.props.Width} onChange={this.onChange.bind(this, "Width")} />
          <span>&nbsp;x&nbsp;</span>
          <InputLine inline="true" value={this.props.Depth} onChange={this.onChange.bind(this, "Depth")} />
        </td>
        <td><a href="javascript:void(0);" className="noprint" onClick={this.props.onDelete}>X</a></td>
      </tr>
    )
  }
}