import React from 'react'
import './App.css'
import * as AWS from 'aws-sdk'
import Editor from './Editor'
import List from './List'
import Cloud from './Cloud'
import { route } from './Util'
const env = require("./env.json")

export default class App extends React.Component {
  constructor() {
    super()
    this.cloud = new Cloud()
    this.state = {
      authenticated: false,
      selectedId: null,
      view: null
    }
  }

  componentWillMount() {
    this.checkAuthentication(() => {
      if ( !this.state.authenticated ) {
        window.location = env.LoginUrl + window.location.href
      }
      else {
        this.onHashChange()
        this.hashListener = this.onHashChange.bind(this)
        window.addEventListener("hashchange", this.hashListener, false)
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.hashListener)
  }

  onHashChange() {
    var nextState = {}
    var params = new URLSearchParams(window.location.hash.substr(1))
    if ( params.has("v") ) nextState.view = params.get("v")
    if ( params.has("id") ) {
      nextState.selectedId = params.get("id")
    }
    else {
      nextState.selectedId = null
    }
    this.setState(nextState)
  }

  getCognitoToken() {
    var params = new URLSearchParams(window.location.hash.substr(1))
    if ( params.has("id_token") ) {
      var id_token = params.get("id_token")
      window.localStorage.setItem("id_token", id_token)
      window.location.hash = ""
      return id_token
    }

    if ( window.localStorage.getItem("id_token") ) {
      return window.localStorage.getItem("id_token")
    }

    return null
  }

  checkAuthentication(done) {
    var id_token = this.getCognitoToken()
    if ( id_token ) {
      var logins = {}
      logins[`cognito-idp.${env.Region}.amazonaws.com/${env.IdentityPoolLoginId}`] = id_token

      AWS.config.update({
        region: env.Region,
        credentials: new AWS.CognitoIdentityCredentials({
          RoleArn: env.RoleArn,
          IdentityPoolId: env.IdentityPoolId,
          Logins: logins
        })
      })

      AWS.config.credentials.refresh(err => {
        if ( err ) {
          window.localStorage.removeItem("id_token")
          window.location = env.LoginUrl + window.location.href
        }
        else {
          this.setState({authenticated: true})
          done()
        }
      })
    }
    else {
      done()
    }
  }

  onSelect(id) {
    route({"id": id, "v": "edit"})
  }

  onBack() {
    route({"v": "home"}, true)
  }

  render() {
    if ( this.state.authenticated === false ) {
      return (
        <div className="msg">
          Logging in...
        </div>
      )
    }

    if ( this.state.selectedId == null ) {
      return (
        <div className="App">
          <List onSelect={this.onSelect.bind(this)} />
        </div>
      )
    }

    return (
      <div className="App">
        <Editor id={this.state.selectedId} onBack={this.onBack.bind(this)} />
      </div>
    )
  }
}