import React from 'react'
import './Page.css'

export default class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <span className="left" dangerouslySetInnerHTML={{__html: this.props.Address}}></span>
        <span className="right"dangerouslySetInnerHTML={{__html: this.props.PackageDate}}></span>
      </div>
    )
  }
}