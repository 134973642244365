import React from 'react'
import './Page.css'

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <span className="left">
          Alberta Real Estate Consulting (AREC)<br />
          www.AlbertaRealEstateConsulting.com<br />
          403-616-6801
        </span>
        <span className="right">&nbsp;<br />Page {this.props.PageNum} of {this.props.TotalPages}</span>
      </div>
    )
  }
}